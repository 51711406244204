<template>
  <div class="body">
    <div class="box">
      <div class="box-left"></div>
      <div class="box-right">
        <div class="box-form">
          <div class="box-title"></div>
          <div class="box-input">
            <div class="box-input-li">
              <div class="box-input-row">
                <div class="box-input-row-img">
                  <img src="../../assets/images/login/icon_user.png"></div>
                <div class="box-input-row-input">
                  <input type="text" placeholder="请输入账号" v-model="loginForm.username"/>
                </div>
              </div>
            </div>
            <div class="box-input-li">
              <div class="box-input-row">
                <div class="box-input-row-img"><img src="../../assets/images/login/icon_psw.png"></div>
                <div class="box-input-row-input">
                  <input type="password" placeholder="请输入密码" v-model="loginForm.password"/>
                </div>
              </div>
            </div>
          </div>
          <div class="box-btn">
            <Button long :loading="submitLoading" style="border:1px solid #3C7CFC;font-size: 24px;letter-spacing: 4px;background-color: #3C7CFC;"
                    type="primary" shape="circle" @click="submit">登录</Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "login",
  components: {},
  data() {
    return {
      submitLoading: false,
      loginForm: {
        username: '',
        password: '',
      }
    }
  },
  created() {
  },
  mounted() {
    let that = this;
    document.onkeydown = function (e) {
      var ev = document.all ? window.event : e;
      if (ev.keyCode == 13) {
        that.submit();
      }
    }
  },
  methods: {
    submit() {
      let md5 = require('md5');
      if (this.loginForm.username != '' && this.loginForm.password != '') {
        this.submitLoading = true
        let password = md5(this.loginForm.password)
        this.$http.login(
            this.loginForm.username,
            password
        ).then(res => {
          this.submitLoading = false
          if (res.status == 1) {
            sessionStorage.setItem("token", res.data.token);
            sessionStorage.setItem('role', res.data.role[0]);
            sessionStorage.setItem('user_info', JSON.stringify(res.data.userInfo));
            window.location.href = "/";
          } else {
            this.$Message.warning('账号或密码错误！');
          }
        })
      } else {
        this.$Message.warning('请输入账号和密码！');
      }
    }
  }
}
</script>

<style scoped lang="less">
@media (max-width: 1200px) {
  .body {
    .box {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 90%;

      .box-right {
        border-radius: 5px;
      }
    }

    .box-left {
      display: none;
    }
  }
}

.box {
  display: flex;
  width: 1200px;
  height: 600px;
  border-radius: 5px;
}

.body {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-image: url('../../assets/images/login/login_bg.png');
  background-size: 100% 100%;


  .box-left {
    width: 762px;
    height: 100%;
    border-radius: 5px 0 0 5px;
    background-color: #93b5ff;
    background-size: 90% 90%;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url('../../assets/images/login/pic_1.png');
  }

  .box-right {
    width: 755px;
    height: 100%;
    border-radius: 0 5px 5px 0;
    background-color: white;
    display: flex;
    justify-content: center;

    .box-form {
      width: 490px;
      height: 100%;
      padding: 0 10px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .box-title {
        width: 250px;
        height: 50px;
        background-image: url('../../assets/images/login/title_3.png');
        background-repeat: no-repeat;
        background-size: 100%;
        margin-top: 100px;

      }

      .box-type {
        display: flex;
        justify-content: space-around;
        width: 100%;
        margin-top: 52px;
        font-size: 21.23px;
        letter-spacing: 1px;

        color: #7E86A0;

        .box-type-li {
          display: flex;
          flex-direction: column;
          align-items: center;

          .box-type-name {
            padding-bottom: 19px;
            cursor: pointer;

            &.active {
              color: #1B274B;
            }
          }

          .box-type-line {
            width: 60px;
            height: 6px;
            background-color: white;
            border-radius: 10px;

            &.active {
              //background-color: #3C7CFC;

            }
          }

          &:hover {
            color: #1B274B;
          }

          &:hover > .box-type-line {
            background-color: #3C7CFC;
          }
        }
      }

      .box-input {
        margin-top: 70px;
        padding: 0 10px;

        .box-input-li {
          width: 100%;
          height: 60px;
          border-radius: 32px;
          margin-bottom: 32px;
          background-color: #F8F8F8;
          overflow: hidden;

          .box-input-row {
            display: flex;
            height: 66px;
            line-height: 60px;

            .box-input-row-img {
              margin: 0 18px 0 30px;
              width: 32px;
              height: 32px;
            }

            .box-input-row-input {

              font-size: 20px;
              color: #7E86A0;

              & > input {
                height: 64px;
                outline: none;
                border: none;
                background-color: #F8F8F8;
                width: 380px;
              }
            }
          }

        }
      }

      .box-btn {
        margin-top: 42px;
        width: 100%;

        Button {
          height: 55px;
        }
      }
    }
  }

}
</style>
